@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family:verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} 

.main-content h3 {font-family:verdana, sans-serif !important;text-transform: uppercase;font-weight: bold;font-size: 1.5em;color: #ffffff !important;text-align: center;margin-right: 2%;text-shadow: 2px 2px 3px black;}
 
.dtlKip {max-width: 100% !important;border: 2px dashed #a8b0b9 !important;} 
.dtlKip svg {display: none;}
.gwDiyc {justify-content: center !important;}
.gwDiyc span:nth-child(2) {display: none;}
.gwDiyc > span {font-size: 1.2em !important;font-weight: bold !important;color: #fff !important;}
.cKeOpf span {text-decoration: none !important}
.dtlKip {height: 200px !important}

.label-left label.form-check-label {display: flex !important;}

.modal-backdrop.show {opacity: 0.9 !important;background-color: #c9283fbf !important;}

.sender-user {width: 80%;text-align: left;float: right;justify-content: flex-start;flex-direction: row;background-color: #fff !important;}
.sender-user p {color: #000000 !important;}
.sender-admin {width: 80%;float: left;width: 80%;flex-direction: row;background-color: #c92741 !important;text-align: left;}
.sender-admin p {color: #fff !important;}
.sender-admin a {color: #fff !important;}

.dtlKip {background-image: url(https://eculab.ai/Assets/logo10.png);flex-grow: 0;background-repeat: no-repeat;background-size: 80%;background-position: 50%;}

.conversation .bg-gray-500 a:hover {text-decoration: underline;}

.subscription-boxes {display: flex;justify-content: space-evenly;}

.subscription-boxes .card {box-shadow: 0 4px 12px 0 rgba(0,0,0,.07),0 2px 4px rgba(0,0,0,.05);border-radius: 15px !important;}

.table th, .table td {vertical-align: middle;}

.main-content button {margin-top: 25px;background-color: #c92641 !important;color: #ffffff !important;box-shadow: none;}

.main-content button:hover {box-shadow: 0 4px 12px 0 rgba(0,0,0,.07),0 2px 4px rgba(0,0,0,.05) !important;}


.form-outline {margin-bottom: 20px;}
.form-outline .form-control.active~.form-label {color: #fff;}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

