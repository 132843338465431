.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-content{
  margin-left: 16%;
  margin-top: 2%;
}

@media screen and (max-width: 700px) {
  .main-content{
    margin-left: 0%;
    margin-top: 0%;
  }
}

.white-label-checkbox {
  color: white;
}

.sidebar {
  flex-direction: column;
  justify-content: space-between;
}

.file-submit-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.file-submit-modal .fa-gear {
  font-size: 100px;
  color: #f8f9fa;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.file-submit-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.file-submit__headline {
  color: #ffffff;
}


.commentHover {
  position: relative;
  /*display: inline-block;
margin-left: 10px;*/
}

.commentHover i {color: #fff;}
.commentHover i:hover {color: #c5293d;}


.commentHover:hover .commentBox {
  display: block;
}

.commentBox {
  background-color: #ffffffe0;
  border: 1px solid #000000;
  border-radius: 15px;
  color: #000;
  display: none;
  left: 50%;
  max-width: 300px;
  min-width: 100px;
  padding: 8px;
  position: absolute;
  top: 20px; /* Adjust based on your layout */
  transform: translateX(-50%);
  white-space: pre-wrap;
  z-index: 100;
    width: 200px;
    margin-top: 10px;
    font-weight: bold;
    margin-left: 30px;
    font-size: 0.8em;
    line-height: 1.3;
}


.closed-status {
    background-color: rgb(198, 40, 64);
    color: rgb(243, 243, 243);
    margin-bottom: 4px;
    padding: 2px;
    text-align: center;
    border-radius: 25px;
    font-size: 0.7em;
    font-weight: bold;
    text-shadow: rgb(0, 0, 0) 1px 1px 1px;
        padding: 5px 20px;
}

.open-status {
    background-color: rgb(0, 166, 0);
    color: rgb(243, 243, 243);
    margin-bottom: 4px;
    padding: 2px;
    text-align: center;
    border-radius: 25px;
    font-size: 0.7em;
    font-weight: bold;
    text-shadow: rgb(0, 0, 0) 1px 1px 1px;
        padding: 5px 20px;
}

/* responsive tables */

/* Stil für die Tabelle auf Desktop-Ansichten */
.table-responsive table {
  width: 100%;
  border-collapse: collapse;
    color: #fff;
}

.table-responsive table th {
  font-weight: bold;
    border-bottom: 1px solid #fff;
}

.table-responsive table td {
    border: 0px;
}

.table-responsive th, .table-responsive td {
  padding: 8px;
  border: 0px solid #ddd;
  text-align: left;
}

/* Responsive Anpassungen für Mobilgeräte */
@media (max-width: 600px) {
    
    .main-content h3 {margin-top: 10% !important;}
    
    
    nav .position-sticky ul {margin-top: 4.5rem !important}
    
    .dtlKip {min-width: 0}
    
    
  .table-responsive table, .table-responsive thead, .table-responsive tbody, .table-responsive th, .table-responsive td, .table-responsive tr {
    display: block;
  }

  .table-responsive thead tr {
    display: none; /* Verstecke die Kopfzeile auf kleinen Bildschirmen */
  }

  .table-responsive td {
    position: relative;
    padding-left: 50% !important;
    text-align: left;
    border-bottom: 1px solid #ddd;
      border: 0;
      color: #fff;
  }
    
      .table-responsive tr {
          margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #fff;
    }
    
    .table-responsive tr:last-child {
          margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: 0px solid #fff;
    }
    
    .table-responsive i.fa-eye {color: #fff;}

  .table-responsive td:before {
    /* Positioniere die Spaltennamen als Labels links von den Daten */
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
}





@media only screen and (max-width: 800px) {
 
    .fas.fa-bars {color: #fff;}
    .navbar.navbar-expand-lg {z-index: 10;}
    .collapse.show.sidebar {z-index: 5;}
    i.fas.fa-bars {color: #fff;}
    .card-body .form-select {margin-bottom: 20px;}
    
}





