body {
    background-color: #000000;
  }
  
  @media (min-width: 992px) {
    .sidebar {
      height: unset!important;
    }
  }
  
  /* Sidebar */
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 58px 0 0;
    /* Height of navbar */
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    width: 240px;
    /* z-index: 600; */
  }

a.list-group-item {text-transform: uppercase}
  
a.list-group-item:hover {color: rgb(201 39 65) !important;transition: color 0.4s ease 0s;}
a.list-group-item:hover i {color: rgb(201 39 65) !important;transition: color 0.4s ease 0s;}

  .sidebar.show {
    height: 100vh;
  }
  
  @media (max-width: 992px) {
    .sidebar {
      width: 100%;
    }
  }
  
  .sidebar .active {
    background-color: rgb(26,34,41);
  }
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto;
    /* Scrollable contents if viewport is shorter than content. */
  }